.new-listing-dropdown-item {
    display: flex;
    align-items: center;
    padding: 0 !important;

    img {
        width: 30px;
        margin-right: 4px;
    }
}
