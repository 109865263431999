.auction-legend-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;


    @media screen and (max-width: 700px) {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 16px;
    }

    img {
        width: 20%;

        @media screen and (max-width: 700px) {
            align-self: center;
            width: 50%;
        }
    }
    
}
